import React from 'react';
import Slider from 'react-slick';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import styled from '@emotion/styled';
import { mq, breakpoint, baseTransitionSecond, easeIn } from 'style/variables';
import sizes from 'style/sizes';
import colors from 'style/colors';
import { EnHeading } from 'components/atoms/enHeading';
import { ContentWrapper } from '../contentWrapper';
import arrowIcon from 'assets/images/icons/icon-arrow-long.svg';
import { arrowHoverKeyFrame, arrowHoverKeyFramePrev } from 'style/mixin';
import { css } from '@emotion/core';

type RangeSlideProps = {
  title?: string;
  perView: 3 | 4;
  viewMore?: string;
  middle?: boolean;
  isplacedbottom?: boolean;
  range?: boolean;
  type?: 'studio';
  isTitleSmall?: boolean;
};

type State = {
  slideCount: number;
  value: number;
  isSp: boolean;
};

const Container = styled.div`
  overflow: hidden;

  & + & {
    margin-top: ${sizes.margin[100]};
  }
`;

const SliderWrapper = styled.div<RangeSlideProps>`
  position: relative;
  ${props =>
    props.range &&
    `
    ${mq.onlypc} {
    padding-bottom: ${sizes.margin[80]};
    }
  `}
  ${props => props.isplacedbottom && `margin-bottom: 0;`}
  .slick-track {
    ${props => props.middle && `display: flex;`}
    ${mq.onlysp} {
      ${props => props.perView > 2 && `display: flex;`};
    }
  }
  .slick-arrow {
    top: 0;
    width: calc((100vw - 1160px) / 2);
    height: 100%;
    transform: none;
    transition: opacity ${baseTransitionSecond}s ${easeIn};
    z-index: 2;
    ${mq.onlysp} {
      display: none !important;
    }
    &::before {
      position: absolute;
      content: '';
      width: 32px;
      height: 32px;
      display: inline-block;
      background-image: url(${arrowIcon});
      top: 124px;
      transition: ${baseTransitionSecond}s ${easeIn};
      opacity: 1;
    }
    &.slick-prev {
      left: calc((100vw - 1240px) / 2 * -1);
      &::before {
        right: 27px;
        transform: rotate(180deg);
      }
      &:hover {
        &::before {
          animation: ${arrowHoverKeyFramePrev} ${baseTransitionSecond * 1.5}s ease both;
        }
      }
    }
    &.slick-next {
      right: calc((100vw - 1240px) / 2 * -1);
      &::before {
        left: 27px;
      }
      &:hover {
        &::before {
          animation: ${arrowHoverKeyFrame} ${baseTransitionSecond * 1.5}s ease both;
        }
      }
    }
    &.slick-disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }
  .slick-list {
    overflow: visible;
  }
  .slick-slider {
    margin: 0 -${props => (props.perView === 3 ? 40 : 32)}px;
    ${mq.onlysp} {
      margin: 0 -${props => (props.perView === 3 ? 15 : 7.5)}px 0;
    }
  }
  .slick-slide {
    transition: opacity ${baseTransitionSecond}s ease;
    &:not(.slick-active) {
      opacity: 0.4;
      pointer-events: none;
    }
    & > div {
      margin: 0 ${props => (props.perView === 3 ? 40 : 32)}px;
      ${mq.onlysp} {
        margin: 0 ${props => (props.perView === 3 ? 16 : 7.5)}px;
        ${props => props.perView === 3 && `width: ${(246 / 375) * 100}vw`}
      }
    }
  }
  .input-range {
    ${mq.onlypc} {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 333px;
    }
    ${mq.onlysp} {
      width: calc(100% - 90px);
      margin: auto;
    }
  }
  .input-range__track.input-range__track--background {
    top: 0;
    height: 11px;
    background-color: inherit;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 1px;
      margin: auto;
      background-color: ${colors.gray02};
    }
  }
  .input-range__track--active {
    background: inherit;
  }
  .input-range__label-container {
    display: none;
  }
  .input-range__slider {
    width: 16px;
    height: 16px;
    background-color: inherit;
    border: 0;
    margin: 0;
    left: -8px;
    top: -6px;
    &:hover {
      &::before {
        transform: scale(1.3);
      }
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 10px;
      height: 10px;
      margin: auto;
      background-color: #242424;
      border-radius: 50%;
      transition: transform ${baseTransitionSecond}s ease;
    }
  }
`;

const Heading = styled(EnHeading)<RangeSlideProps>`
  margin-bottom: ${sizes.margin[48]};
  font-size: 32px;
  ${props => props.type === 'studio' && `text-align: center`}
  ${mq.onlysp} {
    padding-left: 32px;
  }
  ${({ isTitleSmall }) =>
    isTitleSmall &&
    css`
      ${mq.onlysp} {
        font-size: 24px;
      }
    `}
`;

// const ViewMoreWrapper = styled('div')`
//   ${mq.onlypc} {
//     display: flex;
//     justify-content: flex-end;
//     margin-top: 74px;
//   }
//   ${mq.onlysp} {
//     margin-top: ${sizes.margin[64]};
//     text-align: center;
//   }
// `;

// const ViewMore = styled(TextButton)``;

export default class RangeSlide extends React.Component<RangeSlideProps, State> {
  slider: any;

  constructor(props: Readonly<RangeSlideProps>) {
    super(props);
    this.state = { slideCount: 0, value: 0, isSp: false };
  }

  componentDidMount() {
    this.setState({ slideCount: React.Children.count(this.props.children), isSp: window.innerWidth <= breakpoint });
  }

  changeRange(value: any) {
    this.slider.slickGoTo(value);
    this.setState({ value: value });
  }

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: baseTransitionSecond * 1000,
      slidesToShow: this.props.perView,
      slidesToScroll: 1,
      beforeChange: (current: number, next: number) => this.setState({ value: next }),
      waitForAnimate: false,
      responsive: [
        {
          breakpoint: breakpoint + 1,
          settings: {
            infinite: this.props.perView > 2 && true,
            centerMode: this.props.perView > 2 && true,
            variableWidth: this.props.perView > 2 && true,
            slidesToShow: Math.floor(this.props.perView / 2),
          },
        },
      ],
    };
    return (
      <Container>
        <ContentWrapper>
          {this.props.title && <Heading {...this.props}>{this.props.title}</Heading>}
          <SliderWrapper {...this.props} middle={this.props.middle}>
            <Slider {...settings} ref={slider => (this.slider = slider)}>
              {this.props.children}
            </Slider>
            {this.props.range && (
              <InputRange
                maxValue={
                  this.state.slideCount === 0
                    ? 1
                    : this.state.slideCount -
                      (this.state.isSp ? Math.floor(this.props.perView / 2) : this.props.perView)
                }
                minValue={0}
                value={this.state.value}
                onChange={value => this.changeRange(value)}
              ></InputRange>
            )}
          </SliderWrapper>
        </ContentWrapper>
      </Container>
    );
  }
}
